import './bootstrap';


import intlTelInput            from 'intl-tel-input';
// import TomSelect               from 'tom-select/dist/js/tom-select.base';
// import TomSelect_remove_button from 'tom-select/dist/js/plugins/remove_button';
// import 'tom-select/dist/css/tom-select.css';
// import 'tom-select/dist/css/tom-select.bootstrap5.css';
//
// TomSelect.define('remove_button', TomSelect_remove_button);
// window.tomSelects = [];

window.createCORSRequest = (method, url) => {
    let xhr = new XMLHttpRequest();
    if ("withCredentials" in xhr) {
        // XHR for Chrome/Firefox/Opera/Safari.
        xhr.open(method, url, true);
    } else if (typeof XDomainRequest != "undefined") {
        // XDomainRequest for IE.
        xhr = new XDomainRequest();
        xhr.open(method, url);
    } else {
        // CORS not supported.
        xhr = null;
    }
    return xhr;
};

window.initIntlTelInput = el => {
    intlTelInput(el, {
        utilsScript: '/js/utils.js',
        autoHideDialCode: false,
        separateDialCode: true,
        formatOnDisplay: false,
        initialCountry: "auto",
        geoIpLookup(success, failure) {
            let xhr = window.createCORSRequest('GET', "/api/v1/get-country");
            if (!xhr) {
                console.error('CORS not supported');
                return;
            }
            // Response handlers.
            xhr.onload  = function () {
                let response    = JSON.parse(xhr.responseText);
                let countryCode = (response && response.countryCode) ? response.countryCode : "AM";
                success(countryCode);
            };
            xhr.onerror = function () {
                console.error('Woops, there was an error making the request.');
                success('AM');
            };
            xhr.send();
        },
    });
    // tel input change event function
    const telInputChangeEventFunc = function () {
        const itiPhone = intlTelInputGlobals.instances[this.dataset.intlTelInputId];
        if (this.dataset.target) {
            const phoneInput = document.getElementById(this.dataset.target);
            if (phoneInput) {
                let oldValue = phoneInput.value?.trim();
                if (oldValue !== '' && oldValue.charAt(0) !== '+' && oldValue.charAt(0) !== '0' && itiPhone.isValidNumber() === null) {
                    oldValue = `+${oldValue}`;
                    phoneInput.value = oldValue;
                }
                if (itiPhone.getNumber()?.trim() !== '') {
                    if (itiPhone.isValidNumber()) {
                        phoneInput.value = itiPhone.getNumber();
                    } else {
                        phoneInput.value = '';
                    }
                } else {
                    if (oldValue !== '' && itiPhone.isValidNumber() === null) {
                        itiPhone.setNumber(oldValue);
                        phoneInput.value = itiPhone.getNumber();
                    }
                }
                if (phoneInput.value !== oldValue && phoneInput.value !== '' && (itiPhone.isValidNumber() === true || itiPhone.isValidNumber() === null)) {
                    phoneInput.dispatchEvent(new KeyboardEvent('change'));
                    phoneInput.dispatchEvent(new KeyboardEvent('input'));
                    phoneInput.dispatchEvent(new CustomEvent('telchange', {
                        detail: {
                            valid: true,
                            validNumber: phoneInput.value,
                            number: itiPhone.getNumber(),
                            country: itiPhone.getSelectedCountryData().iso2?.toUpperCase(),
                            countryName: itiPhone.getSelectedCountryData().name,
                            dialCode: itiPhone.getSelectedCountryData().dialCode
                        }
                    }));
                } else {
                    if (itiPhone.isValidNumber() === false) {
                        phoneInput.dispatchEvent(new KeyboardEvent('change'));
                        phoneInput.dispatchEvent(new KeyboardEvent('input'));
                        phoneInput.dispatchEvent(new CustomEvent('telchange', {
                            detail: {
                                valid: false,
                                validNumber: phoneInput.value,
                                number: itiPhone.getNumber(),
                                country: itiPhone.getSelectedCountryData().iso2?.toUpperCase(),
                                countryName: itiPhone.getSelectedCountryData().name,
                                dialCode: itiPhone.getSelectedCountryData().dialCode
                            }
                        }));
                    }
                }
            }
        }
    }

    el.removeEventListener('change', telInputChangeEventFunc);
    el.addEventListener('change', telInputChangeEventFunc);

    // listen and sync phone number with tel input if any
    if (el.dataset.target) {
        const phoneInput = document.getElementById(el.dataset.target);
        const itiPhone = intlTelInputGlobals.instances[el.dataset.intlTelInputId];
        if (phoneInput) {
            let oldValue = phoneInput.value?.trim();
            if (oldValue !== '' && oldValue.charAt(0) !== '+' && oldValue.charAt(0) !== '0') {
                oldValue = `+${oldValue}`;
            }
            const changeHandler = function () {
                let newValue = this.value?.trim();
                if (newValue !== oldValue && newValue !== '') {
                    itiPhone.setNumber(newValue);
                }
            }
            phoneInput.removeEventListener('change', changeHandler);
            phoneInput.addEventListener('change', changeHandler);
        }
    }

    // el.addEventListener('change', function (event) {
    //     const instance       = intlTelInputGlobals.instances[event.target.dataset.intlTelInputId];
    //     const intlPhoneInput = document.getElementById(event.target.dataset.target);
    //     intlPhoneInput.value = instance.getNumber();
    //     intlPhoneInput.dispatchEvent(new Event('input'));
    // });
};
window.renderTelInput   = () => {
    const phoneInputs = document.querySelectorAll("input[type=tel]");
    if (phoneInputs.length > 0) {
        phoneInputs.forEach(input => {
            initIntlTelInput(input);
        });
    }
};

//**************** CUSTOM SELECT FUNCTIONALITY START ********************************//
// window.initCustomSelects = (customSelects = null) => {
//     let selects = customSelects ? customSelects : document.querySelectorAll('.custom-select');
//     selects.forEach(select => {
//         if (select.classList.contains('tomselected')) return;
//         let id = select.getAttribute('id');
//         if (id) {
//             let isMultiple = select.getAttribute('multiple'),
//                 config     = {
//                     maxOptions: 5000,
//                     onItemAdd: function () {
//                         if (arguments[1]) {
//                             arguments[1].nextSibling.value = '';
//                             window.tomSelects[id].blur();
//                         }
//                     },
//                 };
//             if (select.classList.contains('tag-select')) {
//                 config.create       = true;
//                 config.createFilter = function (input) {
//                     input = input.toLowerCase();
//                     return !(input in this.options);
//                 }
//             }
//             if (isMultiple) {
//                 config.plugins = ['remove_button']
//             }
//             window.tomSelects[id] = new TomSelect(`#${id}`, config);
//         }
//     });
// };
//**************** CUSTOM SELECT FUNCTIONALITY END   ********************************//

document.addEventListener('DOMContentLoaded', () => {
    //**************** PHONE INPUT FUNCTIONALITY START ********************************//
    // setTimeout(function () {
    //     renderTelInput();
    // }, 5);

    // user dispatched browser events to re-render the tel inputs
    // document.addEventListener("telDOMChanged", function () {
    //     setTimeout(function () {
    //         renderTelInput();
    //     }, 5);
    // });

    // Livewire event hook
    // if (window.Livewire) {
    //     window.Livewire.hook('component.initialized', component => {
    //         setTimeout(function () {
    //             renderTelInput();
    //         }, 5);
    //     });
    // }

    //**************** PHONE INPUT FUNCTIONALITY END ********************************//

    //**************** CUSTOM SELECT FUNCTIONALITY START ********************************//
//     initCustomSelects();
    //**************** CUSTOM SELECT FUNCTIONALITY END   ********************************//
    //**************** FILE INPUT FUNCTIONALITY START ********************************//
    // document.querySelectorAll("[type=file].hidden").forEach(el => {
    //     el.addEventListener("change", function () {
    //         let span         = this.parentNode.querySelector('span.filename');
    //         span.innerHTML   = this.value.split("\\").pop();
    //         let imagePreview = this.parentNode.querySelector('.image-preview');
    //
    //         if (this.files && this.files[0]) {
    //             if (imagePreview) {
    //                 let reader = new FileReader();
    //
    //                 reader.onload = function (event) {
    //                     imagePreview.src               = event.target.result;
    //                     imagePreview.parentNode.hidden = false;
    //                 }
    //
    //                 reader.readAsDataURL(this.files[0]);
    //
    //                 this.parentNode.querySelector('.existing-image').value = '';
    //             }
    //             if (this.dataset.sizeLimit > 0) {
    //                 if (this.files[0].size > this.dataset.sizeLimit) {
    //                     this.parentNode.classList.remove('!border-green-500');
    //                     this.parentNode.classList.add('!border-red-500');
    //                     this.closest('form').querySelector('button').disabled = true;
    //                 } else {
    //                     this.parentNode.classList.remove('!border-red-500');
    //                     this.parentNode.classList.add('!border-green-500');
    //                     this.closest('form').querySelector('button').disabled = false;
    //                 }
    //             }
    //         }
    //     });
    // });
    //**************** FILE INPUT FUNCTIONALITY END       ********************************//
});
